import styled from 'styled-components/macro';
import { colors, typography } from 'roh-frontend/packages/roh-react';
import { BREAKPOINTS, down, makePx } from 'modules/_shared/styles/breakpoints';
import LayoutConstants from 'modules/_shared/styles/constants';

export const Wrapper = styled.div`
  ${typography.body};
  background-color: ${colors.white};
  margin: 0 auto;
  max-width: ${makePx(BREAKPOINTS.desktop)};
  position: relative;
  ${down('tablet')} {
    padding-top: ${makePx(LayoutConstants.headerHeight)};
  }

  footer {
    margin-top: 120px;

    > div:last-child {
      padding: 44px 0 60px;

      a {
        padding: 0;
      }
    }

    ${down('tablet')} {
      margin-top: 40px;
    }
  }
`;

export const ContentWrapper = styled.div`
  margin: 0 auto;
  display: grid;
  grid-template-columns: 100%;
  row-gap: 56px;
  padding-top: 32px;
  position: relative;
  ${down('tablet')} {
    padding: 0 24px;
    row-gap: 32px;
  }

  ${down('mobile')} {
    row-gap: 16px;
  }
`;

export default {
  Wrapper,
  ContentWrapper,
};
