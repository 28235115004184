import { combineReducers } from 'redux';
import homePage from 'modules/Home/reducers/homePage';
import resourcePage from 'modules/Resource/reducers/resource';
import navigation from 'modules/Header/reducers/navigation';
import programmePage from 'modules/Programme/reducers/programme';
import coursePage from 'modules/Course/reducers/course';
import lessonPage from 'modules/Lesson/reducers/lesson';
import unitPage from 'modules/Unit/reducers/unit';
import teacherTrainingPage from 'modules/TeacherTraining/reducers/teacherTraining';
import usersDetails from 'modules/UsersDetails/reducers/usersDetails';
import schoolsMatineesPage from 'modules/SchoolsMatinees/reducers/schoolsMatinees';
import type { ResetReducerStateAction } from 'modules/_shared/actionCreators/resetReducerState';
import breadcrumbs from 'modules/Breadcrumbs/reducers/breadcrumbs';
import { Actions, GenericAction } from './actions';

const appReducers = {
  homePage,
  resourcePage,
  navigation,
  programmePage,
  coursePage,
  lessonPage,
  unitPage,
  teacherTrainingPage,
  schoolsMatineesPage,
  breadcrumbs,
  usersDetails,
};

type AppReducer = typeof appReducers;
type AppReducerKey = keyof AppReducer;

type GenericReducer = (
  state: unknown,
  action: GenericAction<unknown>
) => unknown;

const highOrderReducer = (reducers: AppReducer): AppReducer => {
  const setReducer =
    (reducer: GenericReducer, reducerKey: AppReducerKey) =>
    (state: unknown, action: ResetReducerStateAction) => {
      const { payload, type } = action;
      if (
        type === Actions.RESET_STATE &&
        payload.reducersToReset.has(reducerKey)
      ) {
        return reducer(undefined, action);
      }
      return reducer(state, action);
    };

  return (Object.keys(appReducers) as Array<AppReducerKey>).reduce<AppReducer>(
    (acc, curr) =>
      ({
        ...acc,
        [curr]: setReducer(reducers[curr] as GenericReducer, curr),
      } as unknown as AppReducer),
    {} as AppReducer
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const rootReducer = () => combineReducers(highOrderReducer(appReducers));

export type RootState = ReturnType<ReturnType<typeof rootReducer>>;

export default rootReducer;
