import { createGlobalStyle } from 'styled-components/macro';

const globalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family:"Gotham SSm A","Gotham SSm B", "Montserrat", sans-serif;
  }

  html, body, #root {
    height: 100%;
    width: 100%;
  }

  body {
    font-family:"Gotham SSm A","Gotham SSm B", "Montserrat", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    margin: 0;
  }

  a {
    text-decoration: none;
  }
  
  ul {
    margin: 0;
    padding-inline-start: 20px;
  }
  
  h6 {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
  }
`;

export default globalStyle;
