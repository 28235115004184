import React from 'react';
import { Route, RouteProps, useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useQuery from 'utils/customHooks/useQuery';
import { RootState } from 'store/rootReducer';
import Routes from 'router/routePaths';
import { ApiCallStatus } from 'store/statesHelper';
import PageLoading from '../../ui-components/PageLoading';

type ProtectedRouteProps = {
  component: React.LazyExoticComponent<React.FunctionComponent>;
} & RouteProps;

const ProtectedRoute = ({
  component: Component,
  path,
  ...rest
}: ProtectedRouteProps): React.ReactElement => {
  const { data: userData, status } = useSelector<
    RootState,
    RootState['usersDetails']
  >((state) => state.usersDetails);

  const { isLoggedIn, isAuthorized } = userData || {};
  const isAllowed = useQuery().get('allow');
  const hasAccess = isAuthorized || isAllowed;
  const history = useHistory();

  if (!userData) {
    if (status === ApiCallStatus.FAILURE) {
      return <Redirect push to={Routes.HOME} />;
    }
    return (
      <Route exact path={path}>
        <PageLoading />
      </Route>
    );
  }

  if (!hasAccess) {
    history.push(
      !isLoggedIn
        ? `${Routes.ACCOUNT_SIGNIN}?redirectUrl=${window?.location.toString()}`
        : `${
            Routes.ACCOUNT_EDIT_ABOUT_YOU
          }?redirectUrl=${window?.location.toString()}`
    );
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default ProtectedRoute;
