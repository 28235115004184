import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { BREAKPOINTS } from 'modules/_shared/styles/breakpoints';
import { BreadcrumbsObject } from 'api-doc/types/learning-platform';
import { RootState } from '../../../../store/rootReducer';
import { ReactComponent as LeftArrow } from '../../../../assets/icons/LeftArrow.svg';
import getLinkUrl from '../../../../utils/linkResolver';
import Styled from './styled';

const Breadcrumbs: FunctionComponent = () => {
  const { data, activeUid } = useSelector<RootState, RootState['breadcrumbs']>(
    (state) => state.breadcrumbs
  );

  const allBreadcrumbs = data ? data.slice() : [];

  const getFilteredBreadcrumbs = (breadcrumbs: BreadcrumbsObject[]) => {
    const breadcrumbWithoutUnits = breadcrumbs.filter(
      (breadcrumb: BreadcrumbsObject) => {
        return breadcrumb.value.title.toLowerCase().indexOf('unit') !== 0;
      }
    );
    return breadcrumbWithoutUnits;
  };

  const getBreadcrumbsToDisplay = (breadcrumbs: BreadcrumbsObject[]) => {
    const isMobile = window.innerWidth < BREAKPOINTS.tablet;
    if (isMobile) {
      const FilteredBreadcrumbs = getFilteredBreadcrumbs(breadcrumbs);
      const previousPage =
        FilteredBreadcrumbs.length > 1 ? FilteredBreadcrumbs[1] : null;
      return (
        previousPage && (
          <Styled.StyledNavLink
            href={getLinkUrl(previousPage.value.url)}
            className={
              previousPage.value.url.value.uid === activeUid ? 'active' : ''
            }
          >
            <LeftArrow />
            <span>{previousPage.value.title.toUpperCase()} </span>
          </Styled.StyledNavLink>
        )
      );
    }
    return (
      allBreadcrumbs.length > 1 &&
      allBreadcrumbs.reverse()?.map((item: BreadcrumbsObject, idx: number) => (
        <React.Fragment key={idx}>
          <Styled.StyledNavLink
            href={getLinkUrl(item.value.url)}
            className={item.value.url.value.uid === activeUid ? 'active' : ''}
          >
            {item.value.title.toUpperCase()}
          </Styled.StyledNavLink>
          {idx === allBreadcrumbs.length - 1 ? null : (
            <Styled.SeparatorItem>/</Styled.SeparatorItem>
          )}
        </React.Fragment>
      ))
    );
  };

  return (
    <Styled.Container>
      <Styled.ListWrapper>
        <>{getBreadcrumbsToDisplay(allBreadcrumbs)}</>
      </Styled.ListWrapper>
    </Styled.Container>
  );
};

export default Breadcrumbs;
