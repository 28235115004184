import { ApiError } from 'api/ApiHelper';
import { AxiosRequestConfig } from 'axios';
import { UnknownObject } from 'utils/commonTypes';
import type { Actions, GenericAction } from 'store/actions';

export enum ApiCallStatus {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type ApiState<T> = {
  status: ApiCallStatus;
} & T;

export type RequestTriggerAction<TAction = Actions> = GenericAction<
  {
    extraConfig?: AxiosRequestConfig;
    body?: unknown;
    pathSuffix?: string;
  },
  TAction
>;

export type RequestAction<TActionType, TRequest> = GenericAction<
  {
    request: TRequest;
    status: ApiCallStatus.LOADING;
  },
  TActionType
>;

export type SuccessResponseAction<TActionType, TRequest, TResponse> =
  GenericAction<
    {
      request: TRequest;
      status: ApiCallStatus.SUCCESS;
      response: TResponse;
    },
    TActionType
  >;

export type FailureResponseAction<
  TActionType,
  TRequest,
  TResponse = ApiError
> = GenericAction<
  {
    request: TRequest;
    status: ApiCallStatus.FAILURE;
    response: TResponse;
  },
  TActionType
>;

export type ApiCallActions<
  TLoading = '',
  TSuccess = '',
  TFailure = '',
  TSuccessResponse = UnknownObject,
  TFailResponse = ApiError,
  TRequest = UnknownObject
> =
  | RequestAction<TLoading, TRequest>
  | SuccessResponseAction<TSuccess, TRequest, TSuccessResponse>
  | FailureResponseAction<TFailure, TRequest, TFailResponse>;
