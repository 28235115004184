import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { usersDetails } from 'api';

const fetchUsersDetails = getSimpleRequestHandler({
  actionLoading: Actions.GET_USERS_DETAILS_LOADING,
  actionSuccess: Actions.GET_USERS_DETAILS_SUCCESS,
  actionFailure: Actions.GET_USERS_DETAILS_FAILURE,
  callApiFn: usersDetails.get,
});

export default [takeLatest(Actions.GET_USERS_DETAILS, fetchUsersDetails)];
