import React, { FunctionComponent, useEffect } from 'react';
import { Footer } from 'roh-frontend/packages/roh-react';
import Header from 'views/Header';
import { useDispatch, useSelector } from 'react-redux';
import Breadcrumbs from 'modules/_shared/ui-components/Breadcrumbs';
import { RootState } from 'store/rootReducer';
import getUsersDetails from 'modules/UsersDetails/actionCreators';
import Routes from 'router/routePaths';
import Styled from './styled';

const Layout: FunctionComponent = ({ children }) => {
  const { data: userData } = useSelector<RootState, RootState['usersDetails']>(
    (state) => state.usersDetails
  );
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.setItem('redirectURL', Routes.LOGIN_REDIRECT_URL);
  }, []);

  useEffect(() => {
    if (userData === null) {
      dispatch(getUsersDetails());
    }
  }, [dispatch, userData]);

  return (
    <Styled.Wrapper>
      <Header />
      <Breadcrumbs />
      <Styled.ContentWrapper>{children}</Styled.ContentWrapper>
      <Footer />
    </Styled.Wrapper>
  );
};

export default Layout;
