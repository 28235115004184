import {
  applyMiddleware,
  createStore,
  compose,
  StoreEnhancerStoreCreator,
} from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import rootSaga from 'saga';
import rootReducer from './rootReducer';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];
const enhancers = [devToolsEnhancer({})];
const composeEnhancers = compose<StoreEnhancerStoreCreator>(
  applyMiddleware(...middleware),
  ...enhancers
);

export default createStore(rootReducer(), composeEnhancers);

sagaMiddleware.run(rootSaga);
