import { Actions, GenericAction } from 'store/actions';
import { BreadcrumbsObject } from 'api-doc/types/learning-platform';
import { UnitsApiResponseData } from 'api-doc/types/learning-platform/units-api-response-data';
import { ProgrammesApiResponseData } from 'api-doc/types/learning-platform/programmes-api-response-data';
import { ResourcesApiResponseData } from 'api-doc/types/learning-platform/resources-api-response-data';
import { LessonsApiResponseData } from 'api-doc/types/learning-platform/lessons-api-response-data';
import { HomepageApiResponseData } from 'api-doc/types/learning-platform/homepage-api-response-data';

type State = {
  data: BreadcrumbsObject[] | null;
  activeUid: string | null;
};

const initialState = {
  data: null,
  activeUid: null,
};

export default (
  state: State = initialState,
  action: GenericAction<
    {
      data:
        | UnitsApiResponseData
        | ProgrammesApiResponseData
        | ResourcesApiResponseData
        | LessonsApiResponseData
        | HomepageApiResponseData;
    },
    Actions.SET_BREADCRUMBS
  >
): State => {
  const { payload = null } = action;
  const breadcrumbs = payload?.data?.primary?.breadcrumbs;

  return {
    ...state,
    ...(breadcrumbs ? { data: breadcrumbs } : {}),
    ...(breadcrumbs ? { activeUid: payload!.data.uid } : {}),
  };
};
