import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { lessonPage } from 'api';

const fetchLessonPage = getSimpleRequestHandler({
  actionLoading: Actions.GET_LESSON_LOADING,
  actionSuccess: Actions.GET_LESSON_SUCCESS,
  actionFailure: Actions.GET_LESSON_FAILURE,
  callApiFn: lessonPage.get,
});

export default [takeLatest(Actions.GET_LESSON, fetchLessonPage)];
