import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { homePage } from 'api';

const fetchHomePage = getSimpleRequestHandler({
  actionLoading: Actions.GET_HOME_PAGE_LOADING,
  actionSuccess: Actions.GET_HOME_PAGE_SUCCESS,
  actionFailure: Actions.GET_HOME_PAGE_FAILURE,
  callApiFn: homePage.get,
});

export default [takeLatest(Actions.GET_HOME_PAGE, fetchHomePage)];
