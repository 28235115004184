enum PageTypes {
  HOME = 'homepage',
  RESOURCE = 'resource',
  PROGRAMME_PAGE = 'programme_page',
  COURSE_PAGE = 'course_page',
  LESSON_PAGE = 'lesson_page',
  SCHOOLS_MATINEES = 'schools_matinees',
  UNIT = 'unit',
}

export default PageTypes;
