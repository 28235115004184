import { all, Effect, spawn } from 'redux-saga/effects';
import homePageSagas from 'modules/Home/sagas';
import resourcePageSaga from 'modules/Resource/sagas';
import programmePage from 'modules/Programme/sagas';
import coursePage from 'modules/Course/sagas';
import lessonPage from 'modules/Lesson/sagas';
import unitPage from 'modules/Unit/sagas';
import navigationSagas from 'modules/Header/sagas';
import teacherTrainingPage from 'modules/TeacherTraining/sagas';
import schoolsMatineesPage from 'modules/SchoolsMatinees/sagas';
import usersDetailsSagas from 'modules/UsersDetails/sagas';

const sagas: Array<Effect> = [
  ...homePageSagas,
  ...resourcePageSaga,
  ...programmePage,
  ...coursePage,
  ...lessonPage,
  ...navigationSagas,
  ...unitPage,
  ...teacherTrainingPage,
  ...schoolsMatineesPage,
  ...usersDetailsSagas,
];

/**
 * We use an immortal saga that gives us several benefits
 * (https://github.com/redux-saga/redux-saga/blob/master/docs/advanced/RootSaga.md).
 * If any saga fails, it will be automatically restarted; other sagas won't be affected,
 * which means that the user can operate with the other parts of an application, even if some sagas fail.
 */
function* rootSaga(): IterableIterator<Effect> {
  yield all(
    sagas.map((saga) =>
      spawn(function* sagaSpawn() {
        while (true) {
          try {
            yield (function* wrapper() {
              yield saga;
            })();
            break;
          } catch (e) {
            console.error(e);
          }
        }
      })
    )
  );
}

export default rootSaga;
