import { ApiError } from 'api/ApiHelper';
import { Actions } from 'store/actions';
import { ApiCallStatus, ApiState, ApiCallActions } from 'store/statesHelper';
import { NavigationApiResponse } from 'api-doc/types/learning-platform';

type State = ApiState<{
  status: ApiCallStatus;
  data: NavigationApiResponse | null;
  error: ApiError | null;
}>;

const initialState = {
  status: ApiCallStatus.INITIAL,
  data: null,
  error: null,
};

export default (
  state: State = initialState,
  action: ApiCallActions<
    Actions.GET_NAVIGATION_LOADING,
    Actions.GET_NAVIGATION_SUCCESS,
    Actions.GET_NAVIGATION_FAILURE,
    NavigationApiResponse
  >
): State => {
  switch (action.type) {
    case Actions.GET_NAVIGATION_LOADING:
      return {
        ...state,
        status: action.payload.status,
      };
    case Actions.GET_NAVIGATION_SUCCESS: {
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.response,
      };
    }
    case Actions.GET_NAVIGATION_FAILURE: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.response,
      };
    }
    default:
      return state;
  }
};
