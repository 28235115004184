import { ApiError } from 'api/ApiHelper';
import { Actions, GenericAction } from 'store/actions';
import {
  ApiCallStatus,
  FailureResponseAction,
  SuccessResponseAction,
  RequestAction,
} from 'store/statesHelper';

export const apiCallLoading = (
  action: Actions,
  requestInfo: unknown
): RequestAction<typeof action, typeof requestInfo> => ({
  type: action,
  payload: {
    request: requestInfo,
    status: ApiCallStatus.LOADING,
  },
});

export const apiCallSuccess = (
  action: Actions,
  requestInfo: unknown,
  response: unknown
): SuccessResponseAction<
  typeof action,
  typeof requestInfo,
  typeof response
> => ({
  type: action,
  payload: {
    request: requestInfo,
    status: ApiCallStatus.SUCCESS,
    response,
  },
});

export const apiCallFailure = (
  action: Actions,
  requestInfo: unknown,
  response: ApiError | unknown
): FailureResponseAction<
  typeof action,
  typeof requestInfo,
  typeof response
> => ({
  type: action,
  payload: {
    request: requestInfo,
    status: ApiCallStatus.FAILURE,
    response,
  },
});

export const setBreadcrumbs = (
  action: Actions,
  data: unknown
): GenericAction<typeof data, typeof action> => ({
  type: action,
  payload: data,
});
