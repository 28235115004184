import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { navigation } from 'api';

const fetchNavigation = getSimpleRequestHandler({
  actionLoading: Actions.GET_NAVIGATION_LOADING,
  actionSuccess: Actions.GET_NAVIGATION_SUCCESS,
  actionFailure: Actions.GET_NAVIGATION_FAILURE,
  callApiFn: navigation.get,
});

export default [takeLatest(Actions.GET_NAVIGATION, fetchNavigation)];
