import { ApiError } from 'api/ApiHelper';
import { Actions } from 'store/actions';
import { ApiCallStatus, ApiState, ApiCallActions } from 'store/statesHelper';

type State = ApiState<{
  status: ApiCallStatus;
  // TODO: Replace after back-end is ready
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any | null;
  error: ApiError | null;
}>;

const initialState = {
  status: ApiCallStatus.INITIAL,
  data: null,
  error: null,
};

export default (
  state: State = initialState,
  action: ApiCallActions<
    Actions.GET_USERS_DETAILS_LOADING,
    Actions.GET_USERS_DETAILS_SUCCESS,
    Actions.GET_USERS_DETAILS_FAILURE,
    // TODO: Replace after back-end is ready
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    any
  >
): State => {
  switch (action.type) {
    case Actions.GET_USERS_DETAILS_LOADING:
      return {
        ...state,
        status: action.payload.status,
      };
    case Actions.GET_USERS_DETAILS_SUCCESS: {
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.response.data,
      };
    }
    case Actions.GET_USERS_DETAILS_FAILURE: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.response,
      };
    }
    default:
      return state;
  }
};
