export const BREAKPOINTS = {
  mobile: 768, // 0-768
  tablet: 1024, // 768-1024
  desktop: 1140, // 1024-1140
};

type BreakpointsKeys = keyof typeof BREAKPOINTS;

const makeValuesFromBreakpoint = (name: BreakpointsKeys): [number, number] => {
  switch (name) {
    case 'mobile':
      return [0, BREAKPOINTS.mobile];
    case 'tablet':
      return [BREAKPOINTS.mobile, BREAKPOINTS.tablet];
    case 'desktop':
      return [BREAKPOINTS.tablet, BREAKPOINTS.desktop];
    default:
      return [0, 0];
  }
};

export const makePx = (value: number): string => `${value}px`;

export const up = (name: BreakpointsKeys): string =>
  `@media screen and (min-width: ${makePx(BREAKPOINTS[name])})`;

export const down = (name: BreakpointsKeys): string =>
  `@media screen and (max-width: ${makePx(BREAKPOINTS[name])})`;

export const only = (name: BreakpointsKeys): string => {
  const [start, end] = makeValuesFromBreakpoint(name);
  return `@media screen and (min-width: ${makePx(
    start
  )}) and (max-width: ${makePx(end)})`;
};

export const between = (start: BreakpointsKeys, end: BreakpointsKeys): string =>
  `@media screen and (min-width: ${makePx(
    BREAKPOINTS[start]
  )}) and (max-width: ${makePx(BREAKPOINTS[end])})`;
