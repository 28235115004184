import {
  HomepageApiResponse,
  ResourcesApiResponse,
  ProgrammesApiResponse,
  CoursesApiResponse,
  LessonsApiResponse,
  NavigationApiResponse,
  UnitsApiResponse,
  SchoolsMatineesApiResponse,
  UsersDetailsApiResponse,
} from 'api-doc/types/learning-platform';
import { apiGet, apiUserDetailsGet } from './ApiHelper';

export const homePage = {
  get: apiGet<HomepageApiResponse>('homepage', {}),
};

export const resourcePage = {
  get: apiGet<ResourcesApiResponse>('resources', {}),
};

export const programmePage = {
  get: apiGet<ProgrammesApiResponse>('programmes', {}),
};

export const coursePage = {
  get: apiGet<CoursesApiResponse>('courses', {}),
};

export const lessonPage = {
  get: apiGet<LessonsApiResponse>('lessons', {}),
};

export const unitPage = {
  get: apiGet<UnitsApiResponse>('units', {}),
};

export const schoolsMatineesPage = {
  get: apiGet<SchoolsMatineesApiResponse>('schools-matinees', {}),
};

export const navigation = {
  get: apiGet<NavigationApiResponse>('navigation', {}),
};

export const teacherTrainingPage = {
  // TODO replace type after back-end is ready
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: apiGet<any>('teacher-training', {}),
};

export const usersDetails = {
  get: apiUserDetailsGet<UsersDetailsApiResponse>(
    ['users/details', 'users/roles'],
    {}
  ),
};
