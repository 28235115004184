import { colors } from 'roh-frontend/packages/roh-react';

const LPColors = {
  ...colors,
  persimmon: '#E35205',
  whiteSmoke: '#F0F0F0',
  silverChalice: '#B2B2B2',
  darkGrey: '#727272',
  brightBlue: '#307FE2',
  kellyGreen: '#43B02A',
};

export default LPColors;
