enum Routes {
  HOME = '/schools',
  RESOURCE = '/schools/resource/:uid',
  PROGRAMME = '/schools/programme/:uid',
  COURSE = '/schools/course/:uid',
  LESSON = '/schools/lesson/:uid',
  UNIT = '/schools/unit/:uid',
  TEACHER_TRAINING = '/schools/teacher-training',
  TEACHER_TRAINING_NAV_LEVEL_2 = '/schools/teacher-training/:uid',
  SCHOOLS_MATINEES = '/schools/school-matinees',
  ACCOUNT_SIGNIN = '/account/signin',
  ACCOUNT_LEARNING = '/account/learning',
  ACCOUNT_EDIT_ABOUT_YOU = '/account/edit-about-you',
  LOGIN_REDIRECT_URL = 'learning',
}

export default Routes;
