import styled from 'styled-components/macro';
import { makePx } from 'modules/_shared/styles/breakpoints';
import LayoutConstants from 'modules/_shared/styles/constants';

const Container = styled.div`
  width: 100%;
  height: calc(100vh - ${makePx(LayoutConstants.headerHeight)});
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default {
  Container,
};
