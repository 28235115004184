import { up } from 'modules/_shared/styles/breakpoints';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  ${up('desktop')} {
    & > div > div > div {
      padding-left: 150px;
    }
  }
`;

export default { Wrapper };
