import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { schoolsMatineesPage } from 'api';

const fetchSchoolsMatineesPage = getSimpleRequestHandler({
  actionLoading: Actions.GET_SCHOOLS_MATINEES_LOADING,
  actionSuccess: Actions.GET_SCHOOLS_MATINEES_SUCCESS,
  actionFailure: Actions.GET_SCHOOLS_MATINEES_FAILURE,
  callApiFn: schoolsMatineesPage.get,
});

export default [
  takeLatest(Actions.GET_SCHOOLS_MATINEES, fetchSchoolsMatineesPage),
];
