import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { API_ENDPOINT } from 'utils/envConstants';
import type { UnknownObject } from 'utils/commonTypes';

export type ApiGet<TResponse = unknown> = (arg: {
  extraConfig?: AxiosRequestConfig;
  pathSuffix?: string;
}) => Promise<TResponse>;

export type ApiPost<TBody = UnknownObject, TResponse = unknown> = (arg: {
  extraConfig?: AxiosRequestConfig;
  body?: TBody;
  pathSuffix?: string;
}) => Promise<TResponse>;

const makeUrl = (path: string, pathSuffix = '') =>
  `${API_ENDPOINT}/api/learning-platform/${path}${pathSuffix}`;

export const apiGet =
  <T>(path: string, config: AxiosRequestConfig): ApiGet<T> =>
  async ({ extraConfig = {}, pathSuffix = '' }): Promise<T> => {
    const requestConfig = {
      ...config,
      ...extraConfig,
    };
    const url = makeUrl(path, pathSuffix);
    const { data } = await axios.get(url, requestConfig);
    return data;
  };

export const apiUserDetailsGet =
  <T>(paths: string[], config: AxiosRequestConfig): ApiGet<T> =>
  async ({ extraConfig = {}, pathSuffix = '' }): Promise<T> => {
    const requestConfig = {
      ...config,
      ...extraConfig,
    };
    const urls = paths.map((path) => makeUrl(path, pathSuffix));

    const { data: user } = await axios.get(urls[0], requestConfig);
    const { data: role } = await axios.get(urls[1], requestConfig);

    const aggregatedUserData = {
      data: {
        ...user.data,
        ...role.data,
      },
    } as unknown as T;

    return aggregatedUserData;
  };

export const apiPost =
  <T extends UnknownObject, R = UnknownObject>(
    path: string,
    config: AxiosRequestConfig
  ): ApiPost<T, R> =>
  async ({ extraConfig = {}, body = {}, pathSuffix = '' }): Promise<R> => {
    const requestConfig = {
      ...config,
      ...extraConfig,
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
    };
    const url = makeUrl(path, pathSuffix);
    const { data } = await axios.post(url, body, requestConfig);
    return data;
  };

export type ApiError = {
  status: number | null;
  message: string | null;
};

export const makeApiError = <T = undefined>(
  error: AxiosError<T>
): ApiError => ({
  status: error.response?.status || null,
  message: error.message || null,
});
