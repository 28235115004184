import React, { FunctionComponent } from 'react';
import { Loader } from 'roh-frontend/packages/roh-react';
import Styled from './styled';

const PageLoading: FunctionComponent = () => (
  <Styled.Container>
    <Loader />
  </Styled.Container>
);

export default PageLoading;
