import { Actions } from 'store/actions';
import { RequestTriggerAction } from 'store/statesHelper';

const getUsersDetails =
  (): RequestTriggerAction<Actions.GET_USERS_DETAILS> => ({
    type: Actions.GET_USERS_DETAILS,
    payload: {},
  });

export default getUsersDetails;
