import React from 'react';
import {
  GlobalStyles as ChordGlobalStyles,
  ThemeProvider,
  ThemeType,
} from '@royaloperahouse/chord';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import AppRouter from 'router';
import GlobalStyles from 'modules/_shared/styles/global';
import store from 'store';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={ThemeType.Schools}>
      <ChordGlobalStyles />
      <Provider store={store}>
        <BrowserRouter>
          <GlobalStyles />
          <AppRouter />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
