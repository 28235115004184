import React, { FunctionComponent, useEffect } from 'react';
import { Header } from 'roh-frontend/packages/roh-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/rootReducer';
import { ApiCallStatus } from 'store/statesHelper';
import getNavigation from 'modules/Header/actionCreators';
import PageLoading from 'modules/_shared/ui-components/PageLoading';
import {
  Level1NavItem,
  NavigationApiResponse,
} from 'api-doc/types/learning-platform';
import { SubMenu } from 'roh-frontend/packages/roh-react/dist/Header/Menu/DesktopMenu/data/subMenus';
import Routes from '../../router/routePaths';
import Styled from './styled';

const Layout: FunctionComponent = () => {
  const { data: userData } = useSelector<RootState, RootState['usersDetails']>(
    (state) => state.usersDetails
  );

  const { data, status } = useSelector<RootState, RootState['navigation']>(
    (state) => state.navigation
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNavigation());
  }, [dispatch]);

  const { firstName, lastName, isAuthorized } = userData || {};
  const userName =
    userData !== null && firstName !== '' && lastName !== ''
      ? `${firstName} ${lastName}`
      : '';
  const getMenuItemData = (navData: NavigationApiResponse): SubMenu[] => {
    const { data: navItems } = navData;

    return navItems.map((item: Level1NavItem) => ({
      dataRoh: item.id,
      path: item.attributes.path,
      title: item.attributes.title,
      level2NavItems:
        item.attributes.level2NavItems?.map((l2item) => ({
          ...l2item,
          path: `${Routes.HOME}${l2item.path}`,
        })) || [],
    }));
  };

  return (
    <>
      {status === ApiCallStatus.LOADING && <PageLoading />}
      {status === ApiCallStatus.SUCCESS && (
        <Styled.Wrapper>
          <Header
            menuItems={getMenuItemData(data!)}
            userName={userName}
            isAuthorized={isAuthorized}
            learningPlatform
          />
        </Styled.Wrapper>
      )}
    </>
  );
};

export default Layout;
