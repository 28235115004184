import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { programmePage } from 'api';

const fetchProgrammePage = getSimpleRequestHandler({
  actionLoading: Actions.GET_PROGRAMME_LOADING,
  actionSuccess: Actions.GET_PROGRAMME_SUCCESS,
  actionFailure: Actions.GET_PROGRAMME_FAILURE,
  callApiFn: programmePage.get,
});

export default [takeLatest(Actions.GET_PROGRAMME, fetchProgrammePage)];
