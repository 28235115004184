import React, { FunctionComponent, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Layout from 'modules/_shared/components/Layout';
import Routes from 'router/routePaths';
import PageLoading from 'modules/_shared/ui-components/PageLoading';
import ProtectedRoute from 'modules/_shared/components/ProtectedRoute';

const HomePage = lazy(() => import('views/Home'));
const ResourcePage = lazy(() => import('views/Resource'));
const ProgrammePage = lazy(() => import('views/Programme'));
const CoursePage = lazy(() => import('views/Course'));
const LessonPage = lazy(() => import('views/Lesson'));
const UnitPage = lazy(() => import('views/Unit'));
const TeacherTraining = lazy(() => import('views/TeacherTraining'));
const SchoolsMatineesPage = lazy(() => import('views/SchoolsMatinees'));

const AppRouter: FunctionComponent = () => {
  return (
    <Layout>
      <Suspense fallback={<PageLoading />}>
        <Switch>
          <Route path={Routes.HOME} exact component={HomePage} />
          <Route path={Routes.RESOURCE} exact component={ResourcePage} />
          <Route path={Routes.PROGRAMME} exact component={ProgrammePage} />
          <Route path={Routes.COURSE} exact component={CoursePage} />
          <ProtectedRoute path={Routes.LESSON} exact component={LessonPage} />
          <ProtectedRoute path={Routes.UNIT} exact component={UnitPage} />
          <Route
            path={[
              Routes.TEACHER_TRAINING,
              Routes.TEACHER_TRAINING_NAV_LEVEL_2,
            ]}
            exact
            component={TeacherTraining}
          />
          <Route
            path={Routes.SCHOOLS_MATINEES}
            exact
            component={SchoolsMatineesPage}
          />

          <Route path='*' render={() => <Redirect to={Routes.HOME} />} />
        </Switch>
      </Suspense>
    </Layout>
  );
};

export default AppRouter;
