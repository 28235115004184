import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { unitPage } from 'api';

const fetchUnitPage = getSimpleRequestHandler({
  actionLoading: Actions.GET_UNIT_LOADING,
  actionSuccess: Actions.GET_UNIT_SUCCESS,
  actionFailure: Actions.GET_UNIT_FAILURE,
  callApiFn: unitPage.get,
});

export default [takeLatest(Actions.GET_UNIT, fetchUnitPage)];
