import { EmptyObject } from 'utils/commonTypes';

export enum Actions {
  RESET_STATE = 'RESET_STATE',
  GET_HOME_PAGE = 'GET_HOME_PAGE',
  GET_HOME_PAGE_LOADING = 'GET_HOME_PAGE_LOADING',
  GET_HOME_PAGE_SUCCESS = 'GET_HOME_PAGE_SUCCESS',
  GET_HOME_PAGE_FAILURE = 'GET_HOME_PAGE_FAILURE',
  GET_RESOURCE = 'GET_RESOURCE',
  GET_RESOURCE_LOADING = 'GET_RESOURCE_LOADING',
  GET_RESOURCE_SUCCESS = 'GET_RESOURCE_SUCCESS',
  GET_RESOURCE_FAILURE = 'GET_RESOURCE_FAILURE',
  GET_NAVIGATION = 'GET_NAVIGATION',
  GET_NAVIGATION_LOADING = 'GET_NAVIGATION_LOADING',
  GET_NAVIGATION_SUCCESS = 'GET_NAVIGATION_SUCCESS',
  GET_NAVIGATION_FAILURE = 'GET_NAVIGATION_FAILURE',
  GET_PROGRAMME = 'GET_PROGRAMME',
  GET_PROGRAMME_LOADING = 'GET_PROGRAMME_LOADING',
  GET_PROGRAMME_SUCCESS = 'GET_PROGRAMME_SUCCESS',
  GET_PROGRAMME_FAILURE = 'GET_PROGRAMME_FAILURE',
  GET_COURSE = 'GET_COURSE',
  GET_COURSE_LOADING = 'GET_COURSE_LOADING',
  GET_COURSE_SUCCESS = 'GET_COURSE_SUCCESS',
  GET_COURSE_FAILURE = 'GET_COURSE_FAILURE',
  GET_LESSON = 'GET_LESSON',
  GET_LESSON_LOADING = 'GET_LESSON_LOADING',
  GET_LESSON_SUCCESS = 'GET_LESSON_SUCCESS',
  GET_LESSON_FAILURE = 'GET_LESSON_FAILURE',
  GET_UNIT = 'GET_UNIT',
  GET_UNIT_LOADING = 'GET_UNIT_LOADING',
  GET_UNIT_SUCCESS = 'GET_UNIT_SUCCESS',
  GET_UNIT_FAILURE = 'GET_UNIT_FAILURE',
  GET_SCHOOLS_MATINEES = 'GET_SCHOOLS_MATINEES',
  GET_SCHOOLS_MATINEES_LOADING = 'GET_SCHOOLS_MATINEES_LOADING',
  GET_SCHOOLS_MATINEES_SUCCESS = 'GET_SCHOOLS_MATINEES_SUCCESS',
  GET_SCHOOLS_MATINEES_FAILURE = 'GET_SCHOOLS_MATINEES_FAILURE',
  SET_BREADCRUMBS = 'SET_BREADCRUMBS',
  GET_TEACHER_TRAINING = 'GET_TEACHER_TRAINING',
  GET_TEACHER_TRAINING_LOADING = 'GET_TEACHER_TRAINING_LOADING',
  GET_TEACHER_TRAINING_SUCCESS = 'GET_TEACHER_TRAINING_SUCCESS',
  GET_TEACHER_TRAINING_FAILURE = 'GET_TEACHER_TRAINING_FAILURE',
  GET_USERS_DETAILS = 'GET_USERS_DETAILS',
  GET_USERS_DETAILS_LOADING = 'GET_USERS_DETAILS_LOADING',
  GET_USERS_DETAILS_SUCCESS = 'GET_USERS_DETAILS_SUCCESS',
  GET_USERS_DETAILS_FAILURE = 'GET_USERS_DETAILS_FAILURE',
}

export type GenericAction<T = EmptyObject, A = Actions> = {
  type: A;
  payload: T;
};
