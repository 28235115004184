import { ApiError } from 'api/ApiHelper';
import { Actions } from 'store/actions';
import { ApiCallStatus, ApiState, ApiCallActions } from 'store/statesHelper';
import {
  LessonsApiResponse,
  LessonsApiResponseData,
} from 'api-doc/types/learning-platform';

type State = ApiState<{
  status: ApiCallStatus;
  data: LessonsApiResponseData | null;
  error: ApiError | null;
}>;

const initialState = {
  status: ApiCallStatus.INITIAL,
  data: null,
  error: null,
};

export default (
  state: State = initialState,
  action: ApiCallActions<
    Actions.GET_LESSON_LOADING,
    Actions.GET_LESSON_SUCCESS,
    Actions.GET_LESSON_FAILURE,
    LessonsApiResponse
  >
): State => {
  switch (action.type) {
    case Actions.GET_LESSON_LOADING:
      return {
        ...state,
        status: action.payload.status,
      };
    case Actions.GET_LESSON_SUCCESS: {
      return {
        ...state,
        status: action.payload.status,
        data: action.payload.response.data,
      };
    }
    case Actions.GET_LESSON_FAILURE: {
      return {
        ...state,
        status: action.payload.status,
        error: action.payload.response,
      };
    }
    default:
      return state;
  }
};
