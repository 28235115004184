import { typography } from 'roh-frontend/packages/roh-react';
import styled from 'styled-components/macro';
import LPColors from 'modules/_shared/styles/colors';
import { down, up } from 'modules/_shared/styles/breakpoints';

const Container = styled.div`
  margin: 16px 0;
  ${typography.smallBody}
  display: flex;
  flex-direction: row;

  .last-item {
    display: none;
  }
`;

const SeparatorItem = styled.span`
  color: ${LPColors.darkGrey};
  margin: 0 8px;
  display: inline-block;
`;

const NavItem = styled.a`
  margin: 0 16px;
  color: ${LPColors.black};
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const StyledNavLink = styled.a`
  color: ${LPColors.black};
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  ${down('tablet')} {
    padding: 0 24px;
    span {
      margin-top: 2px;
    }
  }
  ${up('tablet')} {
    :last-child {
      color: ${LPColors.darkGrey};
    }
  }

  svg {
    margin-right: 13px;
  }
`;

const ArrowLinkWrapper = styled.a`
  svg {
    transform: rotateY(180deg);
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export default {
  Container,
  NavItem,
  StyledNavLink,
  SeparatorItem,
  ArrowLinkWrapper,
  ListWrapper,
};
