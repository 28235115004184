import {
  AnyLink,
  FieldTypes,
  Link,
  LinkedDocument,
} from 'api-doc/types/learning-platform';
import Routes from 'router/routePaths';
import { LinkedMedia } from 'api-doc/types/learning-platform/linked-media';
import PageTypes from './PageTypes';

const ALLOW = 'allow=true';

export const routeWithParams = (
  route: Routes | string,
  params: Record<string, string | number>,
  isOfferPageRoute?: boolean
): string => {
  const pathToPage = Object.keys(params).reduce(
    (path, paramName) =>
      path.replace(`:${paramName}`, params[paramName] as string),
    route
  );
  return isOfferPageRoute ? `${pathToPage}?${ALLOW}` : pathToPage;
};

export const getLinkTarget = (link: AnyLink): string | undefined => {
  if ('target' in link && link.type === FieldTypes.LINK)
    return link.target ? link.target : undefined;
  return undefined;
};

const getLinkUrl = (link: AnyLink, isOfferPageRoute?: boolean): string => {
  if (link.type === FieldTypes.LINK) {
    const externalLink = link as Link;
    return externalLink.value;
  }
  if (link.type === FieldTypes.LINKMEDIA) {
    const externalLink = link as LinkedMedia;
    return externalLink.value.url;
  }
  const documentLink = link as LinkedDocument;
  const { uid, type } = documentLink.value;

  switch (type) {
    case PageTypes.HOME:
      return Routes.HOME;
    case PageTypes.RESOURCE:
      return routeWithParams(Routes.RESOURCE, { uid });
    case PageTypes.PROGRAMME_PAGE:
      return routeWithParams(Routes.PROGRAMME, { uid });
    case PageTypes.COURSE_PAGE:
      return routeWithParams(Routes.COURSE, { uid });
    case PageTypes.LESSON_PAGE:
      return routeWithParams(Routes.LESSON, { uid }, isOfferPageRoute);
    case PageTypes.UNIT:
      return routeWithParams(Routes.UNIT, { uid });
    case PageTypes.SCHOOLS_MATINEES:
      return Routes.SCHOOLS_MATINEES;
    default:
      return '';
  }
};

export default getLinkUrl;
