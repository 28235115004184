import { takeLatest } from 'redux-saga/effects';
import getSimpleRequestHandler from 'saga/sagaHelpers';
import { Actions } from 'store/actions';
import { teacherTrainingPage } from 'api';

const fetchTeacherTrainingPage = getSimpleRequestHandler({
  actionLoading: Actions.GET_TEACHER_TRAINING_LOADING,
  actionSuccess: Actions.GET_TEACHER_TRAINING_SUCCESS,
  actionFailure: Actions.GET_TEACHER_TRAINING_FAILURE,
  callApiFn: teacherTrainingPage.get,
});

export default [
  takeLatest(Actions.GET_TEACHER_TRAINING, fetchTeacherTrainingPage),
];
