import { put, call, Effect } from 'redux-saga/effects';
import type { AxiosError } from 'axios';
import { Actions } from 'store/actions';
import {
  apiCallLoading,
  apiCallFailure,
  apiCallSuccess,
  setBreadcrumbs,
} from 'modules/_shared/actionCreators/ApiCall';
import { ApiGet, ApiPost, makeApiError } from 'api/ApiHelper';
import { RequestTriggerAction } from 'store/statesHelper';

function getSimpleRequestHandler<
  TActionTrigger extends RequestTriggerAction,
  ResponseSuccess
>(requestParams: {
  actionLoading: Actions;
  actionSuccess: Actions;
  actionFailure: Actions;
  callApiFn: ApiPost | ApiGet;
}) {
  return function* simpleRequestHandlerSaga(
    action: TActionTrigger
  ): IterableIterator<Effect> {
    const { callApiFn, actionLoading, actionSuccess, actionFailure } =
      requestParams;
    try {
      yield put(apiCallLoading(actionLoading, action.payload));
      const response = (yield call<typeof callApiFn>(
        callApiFn,
        action.payload
      )) as unknown as ResponseSuccess;
      yield put(setBreadcrumbs(Actions.SET_BREADCRUMBS, response));
      yield put(apiCallSuccess(actionSuccess, action.payload, response));
    } catch (error) {
      yield put(
        apiCallFailure(
          actionFailure,
          action.payload,
          makeApiError(error as AxiosError)
        )
      );
    }
  };
}

export default getSimpleRequestHandler;
